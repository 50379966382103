export const listaPais = [
    { name: "Afghanistan" },
    { name: "Aland Islands" },
    { name: "Albania" },
    { name: "Algeria" },
    { name: "American Samoa" },
    { name: "Andorra" },
    { name: "Angola" },
    { name: "Anguilla" },
    { name: "Antarctica" },
    { name: "Antigua and Barbuda" },
    { name: "Argentina" },
    { name: "Armenia" },
    { name: "Aruba" },
    { name: "Australia" },
    { name: "Austria" },
    { name: "Azerbaijan" },
    { name: "Bahamas" },
    { name: "Bahrain" },
    { name: "Bangladesh" },
    { name: "Barbados" },
    { name: "Belarus" },
    { name: "Belgium" },
    { name: "Belize" },
    { name: "Benin" },
    { name: "Bermuda" },
    { name: "Bhutan" },
    { name: "Bolivia, Plurinational State of" },
    { name: "Bonaire, Sint Eustatius and Saba" },
    { name: "Bosnia and Herzegovina" },
    { name: "Botswana" },
    { name: "Bouvet Island" },
    { name: "Brazil" },
    { name: "British Indian Ocean Territory" },
    { name: "Brunei Darussalam" },
  ]