import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react"
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";


 
// const Edit = Loadable(lazy(() => import("./Edit")));
const List = Loadable(lazy(() => import("./ListCargo")));

const cargoRoutes = [
    {
        path: "/cargos",
        element:
            <PrivateRoutes
                auth={authRoles.rh}>
                <List />
            </PrivateRoutes>
    },

];

export default cargoRoutes;