import { Fragment } from "react";
import { Box, Card, Grid, styled, useTheme } from "@mui/material";
import RowCards from "./shared/RowCards";
import StatCards from "./shared/StatCards";
import Campaigns from "./shared/Campaigns";
import StatCards2 from "./shared/StatCards2";
import DoughnutChart from "./shared/Doughnut";
import UpgradeCard from "./shared/UpgradeCard";
import TopSellingTable from "./shared/TopSellingTable";
import { CChart } from "@coreui/react-chartjs";
import { useState } from "react";
import { useApi } from "app/hooks/useApi";
import { useEffect } from "react";
import { Breadcrumb, SimpleCard } from "app/components";
import { H1, H2, H3 } from "app/components/Typography";
import { DonutLarge, Face2Sharp, Folder, Money, MoneySharp, Payment, Person, Settings, Task } from "@mui/icons-material";
import { CCallout, CCol, CRow } from "@coreui/react";
import { Link, useLocation } from "react-router-dom";
import { generateBreadcrumbs } from "app/utils/generateBreadcrumbs";
// import { ChartLine } from "../admin/processos/ChartLine";

// STYLED COMPONENTS
export const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginRight: ".5rem",
  textTransform: "capitalize",
}));

const SubTitle = styled("span")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
}));

const H4 = styled("h4")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginBottom: "16px",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
}));

export default function DashRH() {

  const { palette } = useTheme();

  const [processosMensais, setProcessosMensais] = useState([])
  const [processosDiario, setProcessosDiario] = useState([])
  const visaTypes = ["Turismo", "Trabalho", "Curta Duração", "Fronteira"];
  async function buscarEstatisicas() {
    const processosMensais = await useApi().list("processos/estatistica")
    console.log("PROCESSOS MENSAIS", processosMensais);
    setProcessosMensais(prev => processosMensais?.data)

    const processosDiario = await useApi().list("processos/estatisticadiaria")
    console.log("PROCESSOS Diario", processosDiario);
    setProcessosDiario(prev => processosDiario?.data);
  }

  useEffect(() => {
    buscarEstatisicas()
  }, [])
  const location = useLocation();
  const routeSegments = generateBreadcrumbs(location);
  const [visibleModalAdd, setVisibleModalAdd] = useState()

  return (
    <Fragment>
      <ContentBox className="analytics h-auto">
        <Box className="breadcrumb">
          <Breadcrumb
            routeSegments={routeSegments}
          />
        </Box>
        <Box pt={2}>{/* <Campaigns /> */}</Box>

        <SimpleCard>

          <CRow>
            <CCol>
              <Link to={"/departamentos"}>
                <CCallout>
                  <div>
                    <strong> Departamentos </strong>
                    <H1>{433}</H1 >
                    <Folder></Folder>
                  </div>
                </CCallout>
              </Link>
            </CCol>
            <CCol>
              <Link to={"/Funcionarios"}>
                <CCallout color="success">
                  <div>
                    <strong> Funcionários </strong>
                    <H1>{34}</H1>
                    <Person></Person>
                  </div>
                </CCallout></Link>
            </CCol>
            <CCol>
              <Link to={"/cargos"}>
                <CCallout color="secondary">
                  <div>
                    <strong> Cargos </strong>
                    <H1>{44}</H1>
                    <Settings></Settings>
                  </div>
                </CCallout>
              </Link>
            </CCol>
            <CCol>
              <CCallout color="danger">
                <div>
                  <strong> Saldo</strong>
                  <H1>{55}</H1>
                  <Payment></Payment>
                </div>
              </CCallout>
            </CCol>
            <CCol>
              <CCallout color="warning">
                <div>
                  <strong> Faltas</strong>
                  <H1>{545}</H1>
                  <Task></Task>
                </div>
              </CCallout>
            </CCol>
          </CRow>

        </SimpleCard>
        <Box pt={8}>{/* <Campaigns /> */}</Box>
        <Grid container spacing={3}>

          <Grid item lg={8} md={8} sm={12} xs={12}>

            <TopSellingTable />
            {/* <ChartLine></ChartLine> */}
          </Grid>

          <Grid item lg={4} md={8} sm={12} xs={12}>
            <Card sx={{ px: 3, py: 2, mb: 3 }} style={{ height: "auto" }}>
              <Title>Funcionarios </Title>
              <SubTitle>Últimos 30 dias</SubTitle>
              <CChart
                type="doughnut"
                data={{
                  labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
                  datasets: [
                    {
                      backgroundColor: ['#F9B115', '#E55353', '#1B9E3E', '#95E1FB'],
                      data: [40, 20, 80, 10],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      labels: {
                        color: "#dff",
                      }
                    }
                  },
                }}
              />

            </Card>
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment >
  );
}
