import { useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import "@coreui/coreui/dist/css/coreui.min.css";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
import routes from "./routes";
import "../fake-db";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "./hooks/useAuth";

export default function App() {
  const content = useRoutes(routes);
  const navigate = useNavigate();
  const { logout } = useAuth()

  // Função para redirecionar para o login
  const redirectToLogin = async () => {
    localStorage.clear();
    navigate('/session/signin'); // Adicione a navegação para a página de login
  };

  // Detectar inatividade e redirecionar após 10 segundos sem interação
  useEffect(() => {
    let timeout;
    const handleActivity = () => {
      clearTimeout(timeout);
      timeout = setTimeout(redirectToLogin, 10000); // 10 segundos
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    // Verifica se a página está demorando muito para renderizar
    timeout = setTimeout(redirectToLogin, 10000); // 10 segundos

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, [navigate]);

  return (
    <SettingsProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <AuthProvider>
        <MatxTheme>
          <CssBaseline />
          {content}
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
}
