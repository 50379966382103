import PrivateRoutes from "app/auth/privateRoutes";
import { lazy } from "react"
import { authRoles } from "app/auth/authRoles";
import Loadable from "app/components/Loadable";

// const Edit = Loadable(lazy(() => import("./Edit")));
const List = Loadable(lazy(() => import("./ListDepartamento")));


const DepartamentoRoutes = [
    {
        path: "/departamentos",
        element:
            <PrivateRoutes
                auth={authRoles.rh}>
                <List />
            </PrivateRoutes>
    },

     
    // {
    //     path: "/departamentos/:funcionarioId/editar",
    //     element:
    //         <PrivateRoutes
    //             auth={<Edit />}>

    //         </PrivateRoutes>
    // },

];

export default DepartamentoRoutes;