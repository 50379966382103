import { createContext, useEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { MatxLoading } from "app/components";
import { useApi } from "app/hooks/useApi";
import { NotifyError } from "app/utils/toastyNotification";

const BASE_URL = "http://localhost:4000/";

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false
};

class ManageCurrentAuth {
  async storeAuthToLocalStorage({ key, datas }) {
    localStorage.setItem(key, JSON.stringify(datas));
  }
  async removeAuthToLocalStorage({ key }) {
    localStorage.removeItem(key);
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case "LOGIN": {
      const { user, token } = action.payload;
      const userData = { user, token };
      localStorage.setItem("user", JSON.stringify(userData));
      return { ...state, isAuthenticated: true, user };
    }

    case "LOGOUT": {
      new ManageCurrentAuth().removeAuthToLocalStorage({ key: "user" });
      return { ...state, isAuthenticated: false, user: null };
    }

    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: false, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useApi();

  const login = async (email, senha) => {
    try {
      const response = await api.logar(email, senha);
      const { user } = response.data;
      if (response.status === 200) {
        dispatch({ type: "LOGIN", payload: { user } });
      } else if (response.status === 400) {
        NotifyError(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const register = async (email, username, senha) => {
    try {
      const response = await axios.post("/", { email, username, senha });
      const { user } = response.data;
      dispatch({ type: "REGISTER", payload: { user } });
    } catch (error) {
      console.error(error);
    }
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate('/session/signin'); // Redireciona para a página de login após o logout
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await api.getPerfil(`auth/perfil`);
        if (response.status === 401) {
          dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
        } else if (response.status === 200) {
          dispatch({ type: "INIT", payload: { isAuthenticated: true, user: response.data.user } });
        }
      } catch (error) {
        console.error(error);
        dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, [api]);

  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
